import React from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate= useNavigate();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        // animationData: Lottie9,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleClick=(event)=>{
        navigate(event)
    }
    return (
        <div className="container-fluid ptb-70" data-cursor-color="var(--primary-bg-color)" data-cursor-size="20px">
           <div className="row d-flex m-0 justify-content-center">
            <div className="col-lg-6 mb-2">
            <Lottie
                options={defaultOptions}
                height="65vh"
                width="100%" />
          <div className="row d-flex justify-content-center p-0 m-0">
            <div className="col-lg-4 col-12 text-center">
            <p>Sorry, the page you are looking for does not exist.</p>
            <button className='universalBtn fs-6' onClick={()=>handleClick("/")}>Go Back To Home</button>
            </div>
          </div>
            </div>
           </div>
            
        </div>
    );
};

export default NotFound;
