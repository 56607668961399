import Layout from "./Layout";
import NavbarSection from "./Navs";
function App() {
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
