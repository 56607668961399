import React from 'react'

const Loader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center bg-dark' style={{height:"100vh"}}>
        <div className='text-center text-light'>Loading...</div>
    </div>
  )
}

export default Loader;