// import React from "react";
// import { NavLink, Outlet} from "react-router-dom";
// import Footer from "./Component/Footer/footer";
// import {Navs} from './styles/index';
// import NavbarSection from "./Navs";
// import { useState } from "react";
// import { FiMenu, FiX } from "react-icons/fi";


// const MainNavbar = () => {

//   const [open, setOpen] = useState(false);

//   const handleClick = () => {
//     setOpen(!open);
//   };

//   const closeMenu = () => {
//     setOpen(false);
//   };

//   return (
//     <div className="">
//       <NavbarSection />
//     <nav className="navbar">
       
//       <NavLink to="/" className="nav-logo">
//         Logo
//       </NavLink>
//       <div onClick={handleClick} className="nav-icon">
//         {open ? <FiX /> : <FiMenu />}
//       </div>
//       <ul className={open ? "nav-links active" : "nav-links"}>
//         <li className="nav-item">
//           <NavLink to="/" className="nav-link ordered2" onClick={closeMenu}>
//             Home
//           </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink to="/about" className="nav-link ordered2" onClick={closeMenu}>
//             About
//           </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink to="/contact" className="nav-link ordered2" onClick={closeMenu}>
//             Contact
//           </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink to="/blog" className="nav-link ordered2" onClick={closeMenu}>
//             Blog
//           </NavLink>
//         </li>
//       </ul>
//     </nav>
//     <Outlet />
//     <Footer />
//   </div>
//   );
// };
// export default MainNavbar;








import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "./Component/Footer/footer";
import NavbarSection from "./Navs";
import { FiMenu, FiX } from "react-icons/fi";
import img1 from './images/Nav/yoo_transparent_black.png'
// import { FaHome, FaBlog } from "react-icons/fa";
// import { MdWifiCalling2 } from "react-icons/md";
// import { BsInfoCircleFill } from "react-icons/bs";


const MainNavbar = () => {
  const [open, setOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true); // Initially set to true
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleClick = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollingUp = prevScrollPos > scrollPosition;
      
      if (scrollPosition <= 0.5) {
        setShowNavbar(true); // Show navbar at the top of the page
      } else if (scrollingUp) {
        setShowNavbar(true); // Show navbar only when scrolling back up
      } else {
        setShowNavbar(false); // Hide navbar when scrolling down
      }
      
      setPrevScrollPos(scrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className="">
      <NavbarSection />
      <nav className={`navbar navbar_white mx-0 ${showNavbar ? 'show' : ''}`}>
        <div className="" style={{width:"100px",height:"auto"}}>

        <div className="Logo">
          <a className="option" href="/">
            <img
              src={img1}
              alt="Logo"
              style={{width:"100px" ,height:"auto"}}
            />
          </a>
        </div>
        </div>
        <div onClick={handleClick} className="nav-icon">
          {open ? <FiX /> : <FiMenu />}
        </div>
        <ul className={open ? "nav-links active" : "nav-links"}>
          
          <li className="nav-item">
            <NavLink to="/" className="nav-link ordered2" onClick={closeMenu}>
            Home 
           
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/about"
              className="nav-link ordered2"
              onClick={closeMenu}
            >
              About
             
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              className="nav-link ordered2"
              onClick={closeMenu}
            >
              Contact 
            
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/blog"
              className="nav-link ordered2"
              onClick={closeMenu}
            >
              Blog 
             
            </NavLink>
          </li>
          <li className="nav-item">
          <div className="social d-flex align-items-cente">
          <button>Download</button>
        </div>
          </li>
        </ul>
      
      </nav>
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainNavbar;






















// import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
// import "./Navbar.css";

// const MainNavbar = () => {
//   return (
    // <div>
    //    <NavbarSection />
    // <nav className="navbar navbar-expand-lg navbar-light minus bg-light">
    //   <div className="container-fluid">
    //     <NavLink className="navbar-brand" to="/"><img className="cmlogo" src={CompanyLogo} alt="" /></NavLink>
    //     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarNav">
    //       <ul className="navbar-nav me-auto mb-2 mb-lg-0 unordered">
    //         <li className="nav-item ordered">
    //           <NavLink className="nav-link ordered2" to="/" 
    //           // activeClassName="active" 
    //           // onClick={()=>{alert('hii')}}
    //           >Home</NavLink>
    //         </li>
    //         <li className="nav-item ordered">
    //           <NavLink className="nav-link ordered2" to="about">About</NavLink>
    //         </li>
    //         <li className="nav-item ordered">
    //           <NavLink className="nav-link ordered2" to="contact" >Contact</NavLink>
    //         </li>
    //         <li className="nav-item ordered">
    //           <NavLink className="nav-link ordered2" to="blog" >Blog</NavLink>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>



// export default Navbar;
//     <Outlet />

//     <Footer />

    
//      </div>
//   );
// };

// export default MainNavbar;
