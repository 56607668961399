import React from "react";
// import { NavLink } from "react-router-dom";
// import { FaXTwitter } from "react-icons/fa6";
// import { FaFacebook } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa6";
import img1 from "./images/Nav/yoo_transparent.png";

const NavbarSection = () => {
  return (
    <div>
      <div className="Load">
        <div className="Logo">
          <a className="option" href="/">
            <img
              src={img1}
              alt="Logo"
              style={{width:"57px" ,height:"auto"}}
            />
          </a>
        </div>
        <div className="Nav-Section justify-content-end">
          <ul className="Nav-optns">
            <li className="list-items1">
              <a className="option" href="/">
                Home
              </a>
            </li>
            <li className="list-items1">
              <a className="option" href="/about">
                About
              </a>
            </li>
            <li className="list-items1">
              <a className="option" href="/contact">
                Contact
              </a>
            </li>
            <li className="list-items1">
              <a className="option" href="/blog">
                Blog
              </a>
            </li>
          </ul>
        <div className="social">
          <button>Download Now</button>
        </div>
        </div>
      </div>
      <div className="content"></div>
    </div>
  );
};

export default NavbarSection;
