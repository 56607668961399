import React from "react";
// import "../../styles/footer.css";
import { car1, car2, car3, Logo } from "../../images/index";
import { FiPhoneCall } from "react-icons/fi";

import Mylogo from "../../images/Nav/yoo_transparent.png";

const Footer = () => {
  return (
    <div>
      <div className="container-fluid bcg mt-5">
        <div className="d-flex flex-row footer mx-0">
          <div className="first-col d-flex flex-row mx-0">
            <div className="logo-section mt-5">
              <img src={Mylogo} className="mylogo" alt=""  style={{width:"200px",height:"auto"}}/>
            </div>
            <div className="para-section mt-5">
              <p>We successfully cope with tasks of varying complexity, provide
                long-term guarantees and regularly master new technologies.</p>
            </div>
          </div>

          <div className="call d-flex justify-content-center align-items-center secondcol">
            <div>
              <FiPhoneCall />
            </div>
            <p>
              <span>Call For Taxi</span>
              <a href="#">1105-199-1105</a>
            </p>
          </div>
        </div>

        <div className="middle-section mt-5">
          <div className="container">
            <div className="row list  mt-3 middle-spacebottom mx-0">
              <div className="col-lg-3 col-md-6 col-sm-padding">
                <div className="footer-item">
                  <div className="title">
                    <h1>Working Hours</h1>
                  </div>
                  <ul className="footer-content">
                    <li>
                      <span>MONDAY - FRIDAY:</span>
                      9.00am To 8.00pm
                    </li>
                    <li>
                      <span>SATURDAY:</span>
                      10.00am To 7.30pm
                    </li>
                    <li>
                      <span>SUNDAY:</span>
                      Close Day!
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-padding">
                <div className="footer-item ">
                  <div className="title">
                    <h1>Usefull Links</h1>
                  </div>
                  <ul className="footer-content special">
                    <li>Taxi Booking</li>
                    <li>Help Center</li>
                    <li>Privacy and Policy</li>
                    <li>Terms of Use</li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-padding">
                <div className="footer-item">
                  <div className="title">
                    <h1>Head Office</h1>
                  </div>
                  <ul className="footer-content">
                    <li>
                      <span>LOCATION:</span>
                      Mankapur, Nagpur, India 440030
                    </li>
                    <li>
                      <span>JOIN US:</span>
                      contact@yoocab.com
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-padding">
                <div className="footer-item">
                  <div className="title">
                    <h1>Newsletter Signup</h1>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Your Email"
                      className="email-box"
                    />
                  </div>
                  <button className="designbtn">Subscribe Now</button>
                </div>
              </div>
            </div>
          </div>
          <div className="cars">
            <div className="movingcar">
              <img src={car1} alt="" />
            </div>
            <div className="movingcar2">
              <img src={car2} alt="" />
            </div>
            <div className="movingcar3">
              <img src={car3} alt="" />
            </div>
          </div>
        </div>

        <div className="container p-3 mt-1">
          <div className="row mx-0">
            <div className="col-lg-12 last text-center">
              <h5>© 2024 YooCab All Rights Reserved.</h5>
              <h5>Design and Devloped by <a className="footer-appzmine" href="" target="_blank">Appzmine Tech Pvt Ltd </a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
