import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import NotFound from "./pages/NotFound.js";
import MainNavbar from "./MainNavbar.js";
import Loader from "./Loader.js";
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Blog = lazy(() => import("./pages/Blog.js"));




const Layout = () => {
// const [Loading, setLoading] = useState(false);
//   useEffect (()=> {
//     setTimeout(()=> {
//       setLoading(true)
//     },1000)
//   },[])
//   if(!Loading){
//     return;
//   }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<MainNavbar />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="blog" element={<Blog />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Layout;
